<template>
    <div>
        <Header></Header>




        <v-content>



            <v-container fluid>
                <br>
                <v-row class="mx-5">
                    <router-link :to="{ name: 'home' }" class="root" style=" font-weight: 600; letter-spacing: 1px; ">
                        Dashboard
                    </router-link>
                    <span style=" font-weight: 600; letter-spacing: 1px; " class="child">/ {{ lastsegment }} </span>
                </v-row><br />
                <br>
                <v-row no-gutters justify="center">
                    <v-col cols="6" md="4">
                        <v-text-field append-icon="fa fa-search" v-model="search" label="Search Sub-Module"
                            variant="underlined"></v-text-field>
                    </v-col>
                </v-row>


                <v-row dense>
                    <v-col class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3" v-for="item in filteredItems"
                        :key="item.text">
                        <router-link :to="{ name: item.route }" style="text-decoration: none">
                            <span style=" cursor: pointer;" @click="sublink_page(item.subLinks)">
                                <v-card style="margin: 5px; height: 200px; " class="card-css1">

                                    <v-card-text>

                                        <center>
                                            <v-avatar size="80px" class="mt-4 p1">
                                                <v-icon size="60px" color="#ffff" class="fas fa-fire fa-3x">{{ item.icon
                                                }}</v-icon>
                                            </v-avatar>

                                        </center>
                                    </v-card-text>

                                    <v-card-title class="justify-center">
                                        <h4>{{ item.text }}</h4>
                                    </v-card-title>
                                </v-card>

                            </span>
                        </router-link>
                    </v-col>
                </v-row>
            </v-container>
        </v-content>

        <Footer :drawer="$store.state.drawer"></Footer>
    </div>
</template>
<script>
import Sidebar from "../components/shared/Sidebar";
import Header from "../components/shared/Header";
import Footer from "../components/shared/Footer";
import crypto from "@/service/crypto.js";
import axios from "axios";
import "@/assets/css/color.css";
// css needs to be imported for each dashboard

export default {
    data() {
        return {
            search: "",
            lastsegment: "",
            drawer: null,
            navLink: [],

        }
    },
    components: {
        Sidebar: Sidebar,
        Header: Header,
        Footer: Footer,
    },
    computed: {
        filteredItems() {
            return this.navLink.subLinks.filter((item) => {
                return (
                    item.text
                        .toLowerCase()
                        .indexOf(this.search.toLowerCase()) > -1
                );
            });
        },
    },
    mounted() {

        if (this.$route.params.id) {
            localStorage.setItem("qwertyuidfghjnbvc", this.$route.params.id)
            var data = crypto.decrypt(this.$route.params.id);
            this.navLink = JSON.parse(data);
            this.lastsegment = this.navLink.text;
        } else {
            var Data1 = localStorage.getItem("qwertyuidfghjnbvc")
            var data = crypto.decrypt(Data1);
            this.navLink = JSON.parse(data);
            this.lastsegment = this.navLink.text;
        }
    },
    methods: {

    },

};
</script>


<style scoped>
.card-css1 {
    background: linear-gradient(to right, darkgray, rgb(181, 148, 148));
    margin-left: 10px;
    border-radius: 10px !important;
    ;

}

.card-css1:hover {
    border: 1px solid rgb(46, 50, 38);
    background: rgba(171, 184, 176, 0.506) !important;
    color: #000 !important;

}

.p1 {
    background: #080708;
}


.card-css1:hover .p1 {

    border: none;
    border-radius: 100%;
    color: white;
    background: rgb(141, 151, 112);

}
</style>